import React from 'react';

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="bg-gray-100">
        <div className="container mx-auto px-6 pt-10 pb-6">
          <p className="text-center">
            &copy; Copyright {new Date().getFullYear()}
, Island Investors. All
            Rights Reserved.
</p>
        </div>
      </footer>
    );
  }
};

export default Footer;
