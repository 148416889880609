import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import logo from '../img/island-logo.png';

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: '',
    };
  }

  render() {
    return (
      <header className="lg:px-16 px-6 bg-white flex flex-wrap items-center lg:py-0 py-2 shadow-2xl">
        <div className="flex-1 flex justify-between items-center">
          <a href="/">
            <img
              src={logo}
              alt="Island Investors"
              className="py-5 h-16 lg:h-20"
            />
          </a>
        </div>
        <label
          htmlFor="menu-toggle"
          className="pointer-cursor mb-0 lg:hidden block"
        >
          <svg
            className="fill-current text-gray-900"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <title>menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </label>
        <input type="checkbox" className="hidden" id="menu-toggle" />
        <div
          className="hidden lg:flex lg:items-center lg:w-auto w-full"
          id="menu"
        >
          <nav>
            <ul
              className="lg:flex items-center justify-between text-base
            text-blue-700 pt-4 lg:pt-0"
            >
              <li>
                <AnchorLink
                  to="/#consulting"
                  className="lg:p-4 py3 px-0 block border-b-2 border-transparent
                   hover:border-green-400"
                  title="Island Consulting"
                />
              </li>
              <li>
                <AnchorLink
                  to="/#investors"
                  className="lg:p-4 py3 px-0 block border-b-2 border-transparent
                   hover:border-green-400"
                  title="Island Investors"
                />
              </li>
              <li>
                <AnchorLink
                  to="/#contact"
                  className="lg:p-4 py3 px-0 block border-b-2 border-transparent
                   hover:border-green-400"
                  title="Contact Us"
                />
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
};

export default Navbar;
